import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

export const Home = () => {
  return (
    <div>
      <Header />
      <div className="page-content">
        <div className="green-cover">
          <div className="home-cover-content">
            <div id="home-image-parent">
              <img id="home-image" src="/imgs/front-page.png" />
            </div>
            <div id="text-section">
              <div id="black-box">
                <div className="heading" style={{ marginBottom: 20 }}>Hi!</div>
                <div className="look-lemons-heading" style={{ marginBottom: 20 }}>I'm <h1 style={{ color: '#cdb85a' }}>Look Lemons</h1></div>
                <div className="love-making-music-text" style={{ marginBottom: 20 }}>I love making music.</div>
                {/* <div className="home-pitch" style={{ marginBottom: 35 }}>We live in a world where there is so much suffering. Cost of living, mental health and conflict. I make music to provide people with a sense of happiness and hope for the future as I believe we can all achieve our dreams in life.</div> */}
                <div className="home-pitch" style={{ marginBottom: 35 }}>I believe in making the world a better place with music.</div>
                <div>
                  <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/track/0dJMfYUgjwTzWVhMdN8SQY?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}